import { connect } from 'react-redux';
import {Dispatch} from 'redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    RouterContainer,
} from 'SourceComponent/Router/Router.container';
import {waitForPriorityLoad} from 'Util/Request/LowPriorityLoad';

export {
    mapStateToProps,
    RouterContainer,
};

/** @namespace Steinkrueger/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    init: () => waitForPriorityLoad().then(
        /** @namespace Steinkrueger/Component/Router/Container/mapDispatchToProps/waitForPriorityLoad/then */
        () => {
            import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                'Store/Config/Config.dispatcher'
            ).then(/** @namespace Steinkrueger/Component/Router/Container/mapDispatchToProps/waitForPriorityLoad/then/then */
                ({ default: dispatcher }) => dispatcher.handleData(dispatch, undefined),
            );
            import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                'Store/Wishlist/Wishlist.dispatcher'
            ).then(/** @namespace Steinkrueger/Component/Router/Container/mapDispatchToProps/waitForPriorityLoad/then/then */
                ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch),
            );
            import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                'Store/MyAccount/MyAccount.dispatcher'
            ).then(/** @namespace Steinkrueger/Component/Router/Container/mapDispatchToProps/waitForPriorityLoad/then/then */
                ({ default: dispatcher }) => dispatcher.handleCustomerDataOnInit(dispatch),
            );
            import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                'Store/Cart/Cart.dispatcher'
            ).then(/** @namespace Steinkrueger/Component/Router/Container/mapDispatchToProps/waitForPriorityLoad/then/then */
                ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch),
            );
        },
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
