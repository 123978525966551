import {AnyAction} from 'redux';

import {SalesManager, SalesRepresentative} from 'Component/Chat/Chat.type';
import {awCaGetCompanyForCurrentCustomer, companyUsersOutput} from 'Query/MyAccountCompany.type';

export enum MyAccountCompanyActionType {
    UPDATE_MY_ACCOUNT_COMPANY_INFO = 'UPDATE_MY_ACCOUNT_COMPANY_INFO',
    UPDATE_SALES_REPRESENTATIVE = 'UPDATE_SALES_REPRESENTATIVE',
    UPDATE_COMPANY_USERS_LIST = 'UPDATE_COMPANY_USERS_LIST',
    UPDATE_SALES_MANAGER = 'UPDATE_SALES_MANAGER',
}

export interface updateAccountCompanyInfoAction extends AnyAction {
    type: MyAccountCompanyActionType.UPDATE_MY_ACCOUNT_COMPANY_INFO;
}

export interface updateSalesRepresentativeAction extends AnyAction {
    type: MyAccountCompanyActionType.UPDATE_SALES_REPRESENTATIVE;
}

export interface updateCompanyUsersListAction extends AnyAction {
    type: MyAccountCompanyActionType.UPDATE_COMPANY_USERS_LIST;
}

export interface updateSalesManagerAction extends AnyAction {
    type: MyAccountCompanyActionType.UPDATE_SALES_MANAGER;
}

export interface MyAccountCompanyStore {
    awCaGetCompanyForCurrentCustomer: awCaGetCompanyForCurrentCustomer;
    salesRepresentative: SalesRepresentative;
    salesManager: SalesManager;
    companyUsersList: companyUsersOutput;
}

export type MyAccountCompanyAction = updateAccountCompanyInfoAction |
updateSalesRepresentativeAction | updateCompanyUsersListAction | updateSalesManagerAction;

declare module 'Util/Store/Store.type' {
    export interface RootState {
        MyAccountCompanyReducer: MyAccountCompanyStore;
    }
}
