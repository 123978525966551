import ContentWrapper from 'Component/ContentWrapper';
import {UrlRewritePageType} from 'Route/UrlRewrites/UrlRewrites.config';
import {
    FallbackComponent as SourceFallbackComponent,
} from 'SourceComponent/Fallback/Fallback.component';
import {ReactElement} from 'Type/Common.type';

import './Fallback.override.style';

/** @namespace Steinkrueger/Component/Fallback/Component */
export class FallbackComponent extends SourceFallbackComponent {
    renderProductFallback(): ReactElement {
        const { actionName: { name, type } = {} } = window;

        if (type !== UrlRewritePageType.PRODUCT) {
            return this.renderDefaultFallback();
        }

        return (
            <main
              block="Fallback"
              elem="ProductPagePlaceholder"
              aria-label="Product page"
            >
                <ContentWrapper
                  wrapperMix={ { block: 'Fallback', elem: 'ProductWrapper' } }
                  label={ __('Main product details') }
                >
                    <div block="Fallback" elem="ProductDetails">
                        <h1 block="Fallback" elem="Heading">
                            { name }
                        </h1>
                    </div>
                    <article block="Fallback" elem="ProductActions">
                        <h1 block="Fallback" elem="Title">
                            { name }
                        </h1>
                    </article>
                    <div block="Fallback" elem="ProductGallery">
                        <div block="Fallback" elem="ProductGallerySlider" />
                    </div>
                </ContentWrapper>
            </main>
        );
    }
}

export default FallbackComponent;
