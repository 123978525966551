import {AnyAction} from 'redux';

import {LatestBlogPostsOutput} from 'Query/LatestBlogPosts.type';

export enum LatestBlogPostsActionType {
    UPDATE_LATEST_POSTS = 'UPDATE_LATEST_POSTS',
}

export interface updateLatestBlogPostsAction extends AnyAction {
    type: LatestBlogPostsActionType.UPDATE_LATEST_POSTS;
}

export interface LatestBlogPostsStore {
    getLatestBlogPosts: LatestBlogPostsOutput;
}

declare module 'Util/Store/Store.type' {
    export interface RootState {
        LatestBlogPostsReducer: LatestBlogPostsStore;
    }
}
