import {Reducer} from 'redux';

import {
    LatestBlogPostsActionType,
    LatestBlogPostsStore,
    updateLatestBlogPostsAction,
} from 'Store/LatestBlogPosts/LatestBlogPosts.type';

/** @namespace Steinkrueger/Store/LatestBlogPosts/Reducer/getInitialState */
export const getInitialState = (): LatestBlogPostsStore => ({
    getLatestBlogPosts: {
        items: [],
    },
});

/** @namespace Steinkrueger/Store/LatestBlogPosts/Reducer/LatestBlogPostsReducer */
export const LatestBlogPostsReducer: Reducer<
LatestBlogPostsStore,
updateLatestBlogPostsAction
> = (
    state = getInitialState(),
    action,
) => {
    switch (action.type) {
    case LatestBlogPostsActionType.UPDATE_LATEST_POSTS: {
        const {getLatestBlogPosts} = action;

        return {
            ...state,
            getLatestBlogPosts,
        };
    }

    default:
        return state;
    }
};

export default LatestBlogPostsReducer;
