import {Reducer} from 'redux';

import {
    MyAccountCompanyAction,
    MyAccountCompanyActionType,
    MyAccountCompanyStore,
} from 'Store/MyAccountCompany/MyAccountCompany.type';

/** @namespace Steinkrueger/Store/MyAccountCompany/Reducer/getInitialState */
export const getInitialState = (): MyAccountCompanyStore => ({
    awCaGetCompanyForCurrentCustomer: {
        name: '',
        email: '',
        street: '',
        city: '',
        region: '',
        postcode: '',
        country_id: '',
        telephone: '',
        sales_representative_id: '',
        admin_info: {
            firstname: '',
            lastname: '',
            email: '',
            job_title: '',
            telephone: '',
        },
    },
    salesRepresentative: {
        name: '',
        info: '',
        phone: '',
        email: '',
        image: '',
    },
    salesManager: {
        manager_id: '',
        name: '',
        phone: '',
        email: '',
    },
    companyUsersList: {
        items: [],
        page_info: {
            current_page: 0,
            page_size: 0,
        },
        total_count: 0,
    },
});

/** @namespace Steinkrueger/Store/MyAccountCompany/Reducer/MyAccountCompanyReducer */
export const MyAccountCompanyReducer: Reducer<
MyAccountCompanyStore,
MyAccountCompanyAction
> = (
    state = getInitialState(),
    action,
) => {
    switch (action.type) {
    case MyAccountCompanyActionType.UPDATE_MY_ACCOUNT_COMPANY_INFO: {
        const {awCaGetCompanyForCurrentCustomer} = action;

        return {
            ...state,
            awCaGetCompanyForCurrentCustomer,
        };
    }

    case MyAccountCompanyActionType.UPDATE_SALES_REPRESENTATIVE: {
        const {salesRepresentative} = action;

        return {
            ...state,
            salesRepresentative,
        };
    }

    case MyAccountCompanyActionType.UPDATE_SALES_MANAGER: {
        const {salesManager} = action;

        return {
            ...state,
            salesManager,
        };
    }

    case MyAccountCompanyActionType.UPDATE_COMPANY_USERS_LIST: {
        const {companyUsersList} = action;

        return {
            ...state,
            companyUsersList,
        };
    }

    default:
        return state;
    }
};

export default MyAccountCompanyReducer;
