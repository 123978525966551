/* eslint-disable max-len */

import { Reducer } from 'redux';

import { Aggregation, AggregationOption } from 'Query/ProductList.type';
import {
    ProductListFilter,
    ProductListInfoAction,
    ProductListInfoActionType,
    ProductListInfoStore,
} from 'SourceStore/ProductListInfo/ProductListInfo.type';

/** @namespace Steinkrueger/Store/ProductListInfo/Reducer/reduceFilters */
export const reduceFilters = (filters: Aggregation[]): Record<string, ProductListFilter> => filters.reduce((
    co,
    item,
) => {
    const {
        request_var: attribute_code,
        name: attribute_label,
        position: attribute_position,
        filter_items,
        is_boolean,
        has_swatch,
    } = item;

    const { attribute_values, attribute_options } = filter_items.reduce(
        (
            attribute: { attribute_values: string[]; attribute_options: Record<string, AggregationOption> },
            option: AggregationOption,
        ) => {
            const { value_string } = option;
            const { attribute_values, attribute_options } = attribute;

            attribute_values.push(value_string);

            return {
                ...attribute,
                attribute_options: {
                    ...attribute_options,
                    [value_string]: option,
                },
            };
        },
        { attribute_values: [], attribute_options: {} },
    );

    return {
        ...co,
        [attribute_code]: {
            attribute_code,
            attribute_label,
            attribute_position,
            attribute_values,
            attribute_type: 'select',
            attribute_options,
            is_boolean,
            has_swatch,
        },
    };
}, {});

/** @namespace Steinkrueger/Store/ProductListInfo/Reducer/getInitialState */
export const getInitialState = (): ProductListInfoStore => ({
    minPrice: 0,
    maxPrice: 0,
    sortFields: {},
    filters: {},
    isLoading: true,
    selectedFilter: {},
});

/** @namespace Steinkrueger/Store/ProductListInfo/Reducer/ProductListReducer */
export const ProductListReducer: Reducer<ProductListInfoStore, ProductListInfoAction> = (
    state: ProductListInfoStore = getInitialState(),
    action,
) => {
    const { type } = action;

    switch (type) {
    case ProductListInfoActionType.UPDATE_PRODUCT_LIST_INFO: {
        const {
            selectedFilter,
            products: {
                sort_fields: sortFields = {},
            } = {},
        } = action;

        const deliveryDateFilters = [
            {
                name: __('Days/Availability'),
                position: -1,
                request_var: 'delivery_date',
                has_swatch: false,
                is_boolean: false,
                filter_items: [],
            },
        ];

        const nextWeek = ['0', '1', '2', '3', '4', '5', '6'];

        nextWeek.forEach((element) => {
            const sampleDate = {
                count: 0,
                label: '',
                swatch_data: null,
                value_string: element,
            };

            const today = new Date();
            const tomorrow = new Date(today);
            const formattedDateNumber = parseInt(element, 10) + 1;
            tomorrow.setDate(today.getDate() + formattedDateNumber);
            sampleDate.label = tomorrow.toLocaleDateString();

            // @ts-ignore
            deliveryDateFilters[0].filter_items.push(sampleDate);
        });

        const deliveryDateAttrs = action.products.filters.filter((item) => item.name.includes('tomorrow'));

        if (deliveryDateAttrs.length) {
            deliveryDateAttrs.forEach((element) => {
                const dateNumber = element.name.replace(/^\D+/g, '');

                const formattedDateNumber = parseInt(dateNumber, 10) || 0;

                const customerFromLocalStorage = localStorage.getItem('customer');
                const formattedCustomerData = customerFromLocalStorage ? JSON.parse(customerFromLocalStorage) : {data: {}};

                if (formattedCustomerData.data.id) {
                    const stringId = formattedCustomerData.data.id.toString();
                    const availableForCustomer = element.filter_items.filter((item) => item.label === stringId);

                    if (availableForCustomer.length) {
                        // @ts-ignore
                        deliveryDateFilters[0].filter_items[formattedDateNumber].count = availableForCustomer[0].count;
                    } else {
                        // @ts-ignore
                        deliveryDateFilters[0].filter_items[formattedDateNumber].count = 0;
                    }
                } else {
                    // @ts-ignore
                    deliveryDateFilters[0].filter_items[formattedDateNumber].count = 0;
                }
            });
        }

        const availableFilters = action.products.filters.filter((item) => !item.name.includes('tomorrow'));

        // @ts-ignore
        availableFilters.push(...deliveryDateFilters);

        return {
            ...state,
            filters: reduceFilters(availableFilters),
            sortFields,
            isLoading: false,
            selectedFilter,
        };
    }

    case ProductListInfoActionType.UPDATE_INFO_LOAD_STATUS: {
        const { isLoading } = action;

        return {
            ...state,
            isLoading,
        };
    }

    default:
        return state;
    }
};

export default ProductListReducer;
