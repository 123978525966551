import { connect } from 'react-redux';

import {UrlRewritePageType} from 'Route/UrlRewrites/UrlRewrites.config';
import {UrlRewriteTypeSpecificProps} from 'Route/UrlRewrites/UrlRewrites.type';
import {
    mapDispatchToProps,
    mapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer,
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';
import history from 'Util/History';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Steinkrueger/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    componentDidMount(): void {
        this.requestUrlRewrite();

        this.initialUrl = location.pathname;
    }

    getTypeSpecificProps(): Partial<UrlRewriteTypeSpecificProps> {
        const {
            actionName: {
                id: actionNameId,
                display_mode: preloadDisplayMode,
            } = {},
            isPrefetchValueUsed,
        } = window;
        const {
            urlRewrite: {
                id = isPrefetchValueUsed ? actionNameId : undefined,
                sku,
                display_mode,
                sort_by,
            },
        } = this.props;

        const isLoading = this.getIsLoading();

        switch (this.getType()) {
        case UrlRewritePageType.PRODUCT:
            /**
                 * In case we are not yet sure what product ID it is:
                 * - check if there is a hint in browser history
                 * - fallback to none
                 */
            if (isLoading) {
                const product = history?.location?.state?.product;

                if (product) {
                    const { sku: historySKU, id } = product;

                    return { productSKU: historySKU, id };
                }

                return {};
            }

            return { productSKU: sku, id };
        case UrlRewritePageType.CMS_PAGE:
            if (isLoading) {
                return { isOnlyPlaceholder: true };
            }

            return { pageIds: id };
        case UrlRewritePageType.CATEGORY:
            /**
                 * In case we are not yet sure what category ID it is:
                 * - check if there is a hint in browser history
                 * - fallback to none
                 */
            if (isLoading) {
                if (history) {
                    const {
                        location: {
                            state: {
                                category,
                                displayMode,
                            } = {},
                        } = {},
                    } = history;

                    if (category && category !== true) {
                        return {
                            categoryIds: category,
                            displayMode: isPrefetchValueUsed ? preloadDisplayMode : displayMode,
                            sort_by,
                        };
                    }
                }

                return {};
            }

            if (history) {
                const {
                    location: {
                        state: {
                            category,
                            displayMode,
                        } = {},
                    } = {},
                } = history;

                if (category && category !== true) {
                    return {
                        categoryIds: category,
                        displayMode: isPrefetchValueUsed ? preloadDisplayMode : displayMode,
                        sort_by,
                    };
                }
            }

            return {
                categoryIds: isPrefetchValueUsed && this.initialUrl === location.pathname ? window.actionName.id : id,
                displayMode: isPrefetchValueUsed ? preloadDisplayMode : display_mode,
                sort_by,
            };
        case UrlRewritePageType.NOTFOUND:
        default:
            return {};
        }
    }

    getType(): UrlRewritePageType | string {
        const { urlRewrite: { type = window.actionName?.type, notFound } } = this.props;

        /**
         * If the URL rewrite is loading, prefer state-defined URL type,
         * else fallback to one defined in HTML document by PHP controller
         * (which is only valid for 1st load).
         */
        if (this.getIsLoading()) {
            // TODO: history.state.state looks like undefined all the time.
            const state = history?.location?.state || {};
            const typeKey = Object.keys(state).find((key) => key in UrlRewritesContainer.stateMapping);

            if (typeKey) {
                return UrlRewritesContainer.stateMapping[ typeKey as keyof typeof UrlRewritesContainer.stateMapping];
            }

            /**
             * Otherwise fallback to other guessed types - from window i.e.
             */
            return '';
        }

        if (notFound) {
            return UrlRewritePageType.NOTFOUND;
        }

        if (type) {
            return type;
        }

        return '';
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
